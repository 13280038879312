import{use} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
import Swal from 'sweetalert2';
import {Navigate} from "react-router-dom";
import api from "../../services/api";
import { useState } from 'react';
import { useActionState } from "react";
import { useFormStatus} from 'react-dom';
import {useRef} from 'react';
import { mask, unMask } from 'remask';  //mascaras
import DataTable from 'react-data-table-component';
import "./lista_novos_clientes.css";
import {Link} from "react-router-dom";
import { Dock } from "react-dock";

//import { useCopyToClipboard } from 'usehooks-ts';

//import copy from 'copy-to-clipboard';

//import {CopyToClipboard} from 'react-copy-to-clipboard';

export function Listar_Novos_clientes() {

    const { nome, Logado } = use(MeuContexto)

    const [dt_pedido, setDtPedido] = useState("");
    const [dt_pedido_2, setDtPedido_2] = useState("");
    
    //const [abre_tela, setAbre_tela] = useState(false);
    
    const func_Consultar = async (previousState, traz) => { 
        
        const Data_1 = traz.get('DT1');             
        const Data_2 = traz.get('DT2');         

        const Retorno_Grid = await func_GRID(Data_1, Data_2);

        return {
            lista: Retorno_Grid,
        }        
       
    }

   const [leva, Acao_01] = useActionState(func_Consultar, { Retorno_Grid:[]})

    return(
        <div>

            <h5>Gestão Novos Clientes</h5>   
            <h6>{nome}</h6>

            <form action={Acao_01}>

                    <div id="tela_55D">

                            <br></br>

                            <div id="css_509">

                                <div>
                                    <h4>Data Inicial</h4>
                                </div>

                                <div id="css_500">   
                                        <div id="css_501">                            
                                            <input name='DT1' type="date" onChange={(e) => setDtPedido(e.target.value)} value={dt_pedido} className="form-control" id="InputEmail" aria-describedby="email" />                    
                                        </div> 
                                        <div>
                                            {dt_pedido}    
                                        </div>                      
                                </div>

                            </div>

                            <div id="css_509">

                                <div>
                                    <h4>Data Final</h4>
                                </div>

                                <div id="css_500">   
                                        <div id="css_501">                            
                                            <input name='DT2' type="date" onChange={(e) => setDtPedido_2(e.target.value)} value={dt_pedido_2} className="form-control" id="InputEmail" aria-describedby="email" />                    
                                        </div> 
                                        <div>
                                            {dt_pedido_2}   
                                        </div>                   
                                </div> 
                            </div>                   
                 
                
                            <div id="css_500">                               

                                <BOTAO/>                                                           

                            </div>

                            <div id="css_500">
                                <Link to="/sub_menu" className="btn btn-outline-primary" id="largura_btn_50" >Sair</Link>
                            </div>                           

                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>


                    <div id = 'css_grid_1'>

                    {leva.lista}

                    </div>
                   

            </form>


        </div>      
        
    )

}

//-----------------------------------------------------------------------------------------------

    //duas Variaveis fora das funções

    var show;

    var var_cod;

    var var_cliente;

//-------------------------------------------------------------------------------------------------

const func_GRID = async (Data_1, Data_2) => {

    var Valor;
 
    try{       
              
        await api.post("/Listar_Primeiros_Clientes", { 
            data_inicio: Data_1,
            data_fim: Data_2,
            })            
       
        .then((response) =>{

            Valor=response.data;           

        }) 

    } catch(error){          
            console.log(error);
    }

    async function func_Chama_WhatsApp(CEL, NOM){

        //Copia para a Área de Transferência
        //await navigator.clipboard.writeText(NOM + ' , \n Obrigado por ter escolhido o Sidyna Lima salon. \n Esperamos lhe ver novamente em breve... \n Grande Abraço! ');
           
            const url = "https://web.whatsapp.com/send?phone=55" + CEL;
            window.open(url, "_blank", "noreferrer");

    } 

       
          
        
        async function func_Dados_Cliente(COD, NOM){
            var_cod=COD;
            var_cliente=NOM;
            show = true;
            <Dock_Novos_Clientes/>;         
        }

        // Cria a Paginação
        const paginationOptions = {
            rowsPerPageText: 'Registros por Página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos'
            };

        
        const columns = [
            {
                cell: (row) => <>
                <button onClick={() => func_Chama_WhatsApp(row.CEL, row.NOM)} className={row.CEL ? "btn btn-success" : "btn btn-success disabled"}><i className="bi bi-whatsapp"></i></button>
                <button onClick={() => func_Dados_Cliente(row.COD, row.NOM)} className="btn btn-primary ms-3"><i className="bi bi-arrow-right-circle"></i></button> 
               
                </>,    
                width: "120px",
                //compact: true,
                right: true
            },    

            {
                name: 'Data',
                selector: row => row.DT,
                width: "95px", 
                compact: true,           //largura
                sortable: true            //cria ordenação 
                
            }, 
            {
                name: 'Cliente',
                selector: row => row.NOM,
                width: "185px",   //largura 
                compact: true,
                sortable: true            //cria ordenação
            },        
            {
                name: 'Celular',          
                selector: row => row.CEL,
                width: "120px",
                compact: true,  
                sortable: false            //cria ordenação 
            },                   
            {
                name: 'Qt.',          
                selector: row => row.SERV,
                width: "30px", 
                compact: true,
                right: true, 
                sortable: false            //cria ordenação 
            },                
            {
                name: 'VENDA',          
                selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.VENDA),
                width: "130px", 
                //compact: true,
                sortable: true,      
                right: true
            },
            {
                name: '',          
                selector: row => row.COD,
                width: "0px", 
                compact: true, 
                sortable: false            //cria ordenação 
            }
        ] 



        return(

                   <div>
                    <Dock_Novos_Clientes /> 

                    <DataTable  columns={columns}                            
                        data={Valor}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    />
                                                      
                    </div>            
        )
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function BOTAO(){    
    const { pending } = useFormStatus();        
    return(
       <div>
           <button className="btn btn-outline-primary" id="largura_btn_50" type='submit' disabled={pending}>
               {pending ? "Consultando..." : "Consultar"}
           </button> 
           
       </div>    
       )   
}
//-----------------------------------------------------------------------------------------------

async function func_Fechar(){
    show = false;
    <Dock_Novos_Clientes/>
}

async function Dock_Novos_Clientes(){ 

    var Resultado_SQL;
    
    try{       
                    
        await api.post("/listar_historico" , {                             
                nome_que_quiser: var_cod
              })
    
        .then((response) =>{

            Resultado_SQL=response.data;           

        }) 

    } catch(error){          
            console.log(error);
    }

    const columns_2 = [
 
        {
          name: 'DATA',
          selector: row => row.DT,
          width: "120px",   //largura
          //compact: true,      
          right: true,
          sortable: true            //cria ordenação 
          
      }, 
      {
          name: 'SERVIÇO',
          selector: row => row.produto,
          //maxwidth: "150px",   //largura 
          width: "180px",
    
          compact: true,   
          sortable: true            //cria ordenação
      },      
      {
          name: 'PAGO',          
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_pago),
          width: "120px", 
          //compact: true,      
          right: true
      },       
      {
          name: 'PROFISSIONAL',          
          selector: row => row.profi,
          width: "170px",  
          compact: true,
          //right: true,
          sortable: true            //cria ordenação 
      }
    
    ]

    // Cria a Paginação
    const paginationOptions = {
    rowsPerPageText: 'Registros por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
    };

    return  <Dock position="right"
    isVisible={show}
    fluid={false}
    size={600}     
    > 
                

                <div id='css_cab'>
                    <div>
                                           
                        <h3>{var_cliente}</h3>                    
                    </div>
                    <div id='css_cab_esq'>
                        <button onClick={() => func_Fechar()} className="btn btn-primary ms-3"><i className="bi bi-skip-start-fill"></i></button> 
                    </div>

                </div>

                <div>                        
                    <DataTable  columns={columns_2}                            
                        data={Resultado_SQL}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
                </div> 

    </Dock> 

}